<template>
  <div>
    <Button
      class="btn-primary"
      @click="requestURL"
      :loading="loader"
    >
      {{ buttonText }}
    </Button>
  </div>
</template>

<script>

import MetaIntegrationService from '@/modules/integrations/services/meta-integration-service'

import Button from '@/components/common/Button/Button'

export default {
  name: 'MetaIntegrationButton',
  props: ['redirectUri', 'text'],
  components: {
    Button
  },
  data () {
    return {
      loader: false
    }
  },
  computed: {
    /**
     * Button text
    */
    buttonText () {
      return this.text || 'Integrar com Meta'
    }
  },
  methods: {
    async requestURL () {
      this.loader = true
      try {
        const data = await MetaIntegrationService.getOAuthURL({
          redirect_uri: this.redirectUri
        })
        console.log(data)
        window.location.href = data.url
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
