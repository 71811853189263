import axios from '@/plugins/axios'

/**
 * Get accounts
 */
const getAccounts = async () => {
  try {
    const response = await axios.get('integrations/google-ads/accounts')
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaigns
 */
const getCampaigns = async ({
  // eslint-disable-next-line camelcase
  customer_id,
  // eslint-disable-next-line camelcase
  manager_id = null
}) => {
  const params = {
    customer_id
  }
  // eslint-disable-next-line camelcase
  if (manager_id) params.manager_id = manager_id
  try {
    const response = await axios.get('integrations/google-ads/campaigns', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getAccounts,
  getCampaigns
}
