import axios from '@/plugins/axios'

const indexCampaigns = async id => {
  try {
    const response = await axios.get('integrations/meta')
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const createCampaign = async (parameters, actId) => {
  try {
    const response = await axios.post(`integrations/meta/${actId}`, parameters)
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

const createCampaignFormData = async (formData) => {
  try {
    const response = await axios.post('integrations/meta', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Definindo cabeçalho apropriado
      }
    })
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Generate access token
 */
const generateAccessToken = async code => {
  try {
    const response = await axios.post('integrations/meta/access-token', {
      code: code
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get oAuth URL
 */
const getOAuthURL = async ({
  // eslint-disable-next-line camelcase
  redirect_uri = null
}) => {
  try {
    const response = await axios.get('integrations/meta/oauth-url', {
      params: {
        redirect_uri
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const integrationEnabled = async (parameters) => {
  try {
    const response = await axios.post('integrations/check', parameters)
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

const insertIntegrationToken = async (parameters) => {
  try {
    const response = await axios.post('integrations/create', parameters)
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

const importCampaigns = async (parameters) => {
  try {
    const response = await axios.post('integrations/meta/campaigns/import', parameters)
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Get accounts from API
 */
const getAccounts = async () => {
  try {
    const response = await axios.get('integrations/meta-ads/accounts')
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Get campaigns from API
 */
const getCampaigns = async ({
  // eslint-disable-next-line camelcase
  account_id
}) => {
  try {
    const response = await axios.get('integrations/meta-ads/campaigns', {
      params: {
        account_id
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  indexCampaigns,
  createCampaign,
  createCampaignFormData,
  generateAccessToken,
  getOAuthURL,
  integrationEnabled,
  insertIntegrationToken,
  importCampaigns,
  getAccounts,
  getCampaigns
}
