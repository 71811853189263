<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('import_campaign') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <div class="ms-3">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'campaigns.index' }"
          >
            {{ $t('cancel') }}
        </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-10 mx-md-auto">
      <div class="text-center mb-3 mt-5">
        <h2 class="font-size-medium highlighted-color1">
          <span>{{ $t('select_platform_import_campaign') }}</span>
        </h2>
      </div>
      <div class="col-12 col-md-6 mx-auto mb-5 default-form">
        <div class="form-group w-100">
            <div class="custom-select">
              <select class="form-control" v-model.trim="form.platform">
                <option value="" disabled selected>{{ $t('choice') }}</option>
                <option value="GOOGLE_ADS">Google Ads</option>
                <option value="META_ADS">Meta Ads</option>
              </select>
            </div>
          </div>
      </div>
      <template v-if="user">
        <!-- GOOGLE ADS -->
        <template v-if="form.platform === 'GOOGLE_ADS'">
          <!-- IS NOT INTEGRATED -->
          <template v-if="!user.company.enabled_integrations.google_ads">
            <p class="text-center">{{ $t('google_ads_campaigns_access') }}</p>
            <div class="text-center mt-4">
              <GoogleIntegrationButton
                :text="$t('grant_access')"
                :redirectUri="redirectUri"
              />
            </div>
          </template> <!-- END IS NOT INTEGRATED -->
          <!-- IS INTEGRATED -->
          <template v-else>
            <div class="text-center" v-if="loader && step === 'google_ads_accounts'">
              <Loader
                :size="'big'"
              />
            </div>
            <template v-else>
              <div class="text-center mb-3">
                <h2 class="font-size-medium highlighted-color1">
                  <span>{{ $t('account_responsible_for_the_campaign') }}</span>
                </h2>
              </div>
              <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                <div class="form-group w-100">
                    <div class="custom-select">
                      <select class="form-control" v-model.trim="google_ads.selected_account">
                        <option value="" disabled selected>{{ $t('choice') }}</option>
                        <option
                          :value="account.customer_id"
                          v-for="(account, index) in google_ads.accounts"
                          :key="index"
                        >
                          {{ account.descriptive_name }} ({{ account.customer_id | VMask('###-###-####') }})
                        </option>
                      </select>
                    </div>
                  </div>
              </div>

              <!-- CAMPAIGNS -->
              <template v-if="google_ads.selected_account">
                <div class="text-center" v-if="loader && step === 'google_ads_campaigns'">
                  <Loader
                    :size="'big'"
                  />
                </div>
                <template v-else>
                  <div class="text-center mb-3">
                    <h2 class="font-size-medium highlighted-color1">
                      <span>{{ $t('select_the_campaign_to_import') }}</span>
                    </h2>
                  </div>
                  <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                    <div class="form-group w-100">
                        <div class="custom-select">
                          <select class="form-control" v-model.trim="form.campaign_id">
                            <option value="" disabled selected>{{ $t('choice') }}</option>
                            <option
                              :value="campaign.id"
                              v-for="(campaign, index) in selectedGoogleAdsAccount.campaigns"
                              :key="index"
                            >
                              {{ campaign.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div class="text-center mb-3">
                    <h2 class="font-size-medium highlighted-color1">
                      <span>{{ $t('advertiser_to_link_the_campaign') }}</span>
                    </h2>
                  </div>
                  <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                    <div class="form-group w-100">
                        <div class="custom-select">
                          <select class="form-control" v-model.trim="form.advertiser_id">
                            <option value="" disabled selected>{{ $t('choice') }}</option>
                            <option
                              :value="advertiser.id"
                              v-for="(advertiser, index) in advertisers"
                              :key="index"
                            >
                              {{ advertiser.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div class="text-center">
                    <Button
                      class="btn btn-primary"
                      type="button"
                      :disabled="!form.campaign_id || !form.advertiser_id"
                      :loading="importing"
                      @click="submit"
                    >
                      {{ $t('import') }}
                    </Button>
                  </div>
                </template>
              </template> <!-- END CAMPAIGNS -->
            </template>
          </template> <!-- END IS INTEGRATED -->
        </template> <!-- END GOOGLE ADS -->

        <!-- META -->
        <template v-if="form.platform === 'META_ADS'">
          <!-- IS NOT INTEGRATED -->
          <template v-if="!user.company.enabled_integrations.meta_ads">
            <p class="text-center">{{ $t('meta_ads_campaigns_access') }}</p>
            <div class="text-center mt-4">
              <MetaIntegrationButton
                :text="$t('grant_access')"
                :redirectUri="redirectUri"
              />
            </div>
          </template> <!-- END IS NOT INTEGRATED -->
          <!-- IS INTEGRATED -->
          <template v-else>
            <div class="text-center" v-if="loader && step === 'meta_accounts'">
              <Loader
                  :size="'big'"
              />
            </div>
            <template v-else>
              <div class="text-center mb-3">
                <h2 class="font-size-medium highlighted-color1">
                  <span>{{ $t('account_responsible_for_the_campaign') }}</span>
                </h2>
              </div>
              <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                <div class="form-group w-100">
                  <div class="custom-select">
                    <select class="form-control" v-model.trim="meta.selected_account">
                      <option value="" disabled selected>{{ $t('choice') }}</option>
                      <option
                          :value="account.id"
                          v-for="(account, index) in meta.accounts"
                          :key="index"
                      >
                        {{ account.name }} ({{ account.id | VMask('###-###-####') }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- CAMPAIGNS -->
              <template v-if="meta.selected_account">
                <div class="text-center" v-if="loader && step === 'meta_campaigns'">
                  <Loader
                      :size="'big'"
                  />
                </div>
                <template v-else>
                  <div class="text-center mb-3">
                    <h2 class="font-size-medium highlighted-color1">
                      <span>{{ $t('select_the_campaign_to_import') }}</span>
                    </h2>
                  </div>
                  <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                    <div class="form-group w-100">
                      <div class="custom-select">
                        <select class="form-control" v-model.trim="form.campaign_id">
                          <option value="" disabled selected>{{ $t('choice') }}</option>
                          <option
                              :value="campaign.id"
                              v-for="(campaign, index) in selectedMetaAccount.campaigns"
                              :key="index"
                          >
                            {{ campaign.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-3">
                    <h2 class="font-size-medium highlighted-color1">
                      <span>{{ $t('advertiser_to_link_the_campaign') }}</span>
                    </h2>
                  </div>
                  <div class="col-12 col-md-6 mx-auto mb-5 default-form">
                    <div class="form-group w-100">
                      <div class="custom-select">
                        <select class="form-control" v-model.trim="form.advertiser_id">
                          <option value="" disabled selected>{{ $t('choice') }}</option>
                          <option
                              :value="advertiser.id"
                              v-for="(advertiser, index) in advertisers"
                              :key="index"
                          >
                            {{ advertiser.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <Button
                        class="btn btn-primary"
                        type="button"
                        :disabled="!form.campaign_id || !form.advertiser_id"
                        :loading="importing"
                        @click="submit"
                    >
                      {{ $t('import') }}
                    </Button>
                  </div>
                </template>
              </template> <!-- END CAMPAIGNS -->
            </template>
          </template> <!-- END IS INTEGRATED -->
        </template> <!-- END META -->

      </template>
    </div>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import GoogleAdsService from '@/modules/integrations/services/google-ads-service'
import MetaIntegrationService from '@/modules/integrations/services/meta-integration-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { mapState } from 'vuex'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import GoogleIntegrationButton from '@/components/google/GoogleIntegrationButton'
import MetaIntegrationButton from '@/components/meta/MetaIntegrationButton'
import Loader from '@/components/common/Loader/Loader'

export default {
  mixins: [variables],
  components: {
    Button,
    GoogleIntegrationButton,
    MetaIntegrationButton,
    Loader
  },
  data () {
    return {
      advertisers: [],
      form: {
        advertiser_id: '',
        campaign_id: '',
        platform: ''
      },
      google_ads: {
        accounts: [],
        selected_account: ''
      },
      importing: false,
      loader: false,
      meta: {
        accounts: [],
        selected_account: ''
      },
      step: 'init'
    }
  },
  metaInfo () {
    return {
      title: this.$t('import_campaign')
    }
  },
  mounted () {
    if (this.$route.query.platform) this.form.platform = this.$route.query.platform
    this.getAdvertisers()
  },
  watch: {
    'form.platform' (newValue, oldValue) {
      if (newValue === 'GOOGLE_ADS' && this.google_ads.accounts.length === 0 && this.user) this.getGoogleAdsAccounts()
      if (newValue === 'META_ADS' && this.meta.accounts.length === 0 && this.user) this.getMetaAccounts()
    },
    'google_ads.selected_account' (newValue, oldValue) {
      this.getGoogleAdsCampaigns(newValue)
    },
    'meta.selected_account' (newValue, oldValue) {
      this.getMetaCampaigns(newValue)
    },
    user (newUser) {
      this.getAdvertisers()
      if (this.form.platform === 'GOOGLE_ADS' && this.google_ads.accounts.length === 0) this.getGoogleAdsAccounts()
      if (this.form.platform === 'META_ADS' && this.meta.accounts.length === 0) this.getMetaAccounts()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Redirect URI
    */
    redirectUri () {
      return `${window.location.href}?platform=${this.form.platform}`
    },
    /**
     * Selected Google Ads Account
    */
    selectedGoogleAdsAccount () {
      if (!this.google_ads.selected_account) return

      return this.google_ads.accounts.find(item => {
        return item.customer_id === this.google_ads.selected_account
      })
    },
    /**
     * Selected Google Ads Account
     */
    selectedMetaAccount () {
      if (!this.meta.selected_account) return

      return this.meta.accounts.find(item => {
        return item.id === this.meta.selected_account
      })
    }
  },
  methods: {
    /**
     * Change step
    */
    changeStep (step) {
      this.step = step
    },
    /**
     * Get advertisers
    */
    async getAdvertisers () {
      if (!this.user) return false

      this.loader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          items_per_page: 100,
          page: 1
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get Google Ads Accounts
    */
    async getGoogleAdsAccounts () {
      if (!this.user.company.enabled_integrations.google_ads) return

      this.loader = true
      this.changeStep('google_ads_accounts')
      try {
        const accounts = await GoogleAdsService.getAccounts()
        const processedAccounts = []

        accounts.forEach(item => {
          // Add it to the array
          const account = JSON.parse(JSON.stringify(item))
          account.campaigns = []
          delete account.children
          processedAccounts.push(account)

          // Parse children
          item.children.forEach(child => {
            // Check if already is included
            const index = processedAccounts.findIndex(i => {
              return i.customer_id === child.customer_id
            })
            const acc = JSON.parse(JSON.stringify(child))
            acc.manager_id = item.customer_id
            if (!acc.campaigns) acc.campaigns = []
            delete acc.children
            // Check if is not included (sometimes accounts appear doubled, both in the main accounts and in the MCC subaccounts)
            if (index < 0) processedAccounts.push(acc)
            else processedAccounts.splice(index, 1, acc)
          })
        })

        processedAccounts.sort((a, b) => (a.descriptive_name > b.descriptive_name) ? 1 : ((b.descriptive_name > a.descriptive_name) ? -1 : 0))

        // Parse accounts
        this.google_ads.accounts = processedAccounts
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get Google Ads Campaigns
    */
    async getGoogleAdsCampaigns (customerId) {
      this.changeStep('google_ads_campaigns')
      this.form.campaign_id = ''
      const index = this.google_ads.accounts.findIndex(item => {
        return item.customer_id === customerId
      })
      const account = this.google_ads.accounts[index]
      // If already has campaigns loaded, return
      if (account.is_loaded_campaigns) return

      this.loader = true
      try {
        const campaigns = await GoogleAdsService.getCampaigns({
          customer_id: customerId,
          manager_id: account.manager_id || null
        })

        campaigns.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        this.google_ads.accounts[index].campaigns = campaigns
        this.google_ads.accounts[index].is_loaded_campaigns = true
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get Meta Accounts
     * @returns {Promise<void>}
     */
    async getMetaAccounts () {
      if (!this.user.company.enabled_integrations.meta_ads) return

      this.loader = true
      this.changeStep('meta_accounts')
      try {
        const accounts = await MetaIntegrationService.getAccounts()
        // Parse accounts
        this.meta.accounts = accounts
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },

    /**
     * Get Meta Campaigns
     */
    async getMetaCampaigns (accountId) {
      this.changeStep('meta_campaigns')
      this.form.campaign_id = ''
      const index = this.meta.accounts.findIndex(item => {
        return item.id === accountId
      })
      const account = this.meta.accounts[index]
      // If already has campaigns loaded, return
      if (account.is_loaded_campaigns) return

      this.loader = true
      try {
        const campaigns = await MetaIntegrationService.getCampaigns({
          account_id: accountId
        })
        this.meta.accounts[index].campaigns = campaigns

        this.meta.accounts[index].is_loaded_campaigns = true
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Creates Meta integration when authorizing
     */
    async insertIntegrationToken (code) {
      if (!this.user) return false
      try {
        // eslint-disable-next-line new-cap
        return await new MetaIntegrationService.insertIntegrationToken({
          code: code,
          company_id: this.user.company.id,
          advertiser_id: this.user.company.advertiser.id
        })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
    },
    async submit () {
      this.importing = true
      try {
        const campaign = await CampaignsService.importCampaign({
          advertiserId: this.form.advertiser_id,
          campaignId: this.form.campaign_id,
          customerId: this.form.platform === 'GOOGLE_ADS' ? this.selectedGoogleAdsAccount.customer_id : this.selectedMetaAccount.id,
          managerId: this.form.platform === 'GOOGLE_ADS' ? this.selectedGoogleAdsAccount.manager_id : null,
          platform: this.form.platform
        })
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('campaign_imported_successfully')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.importing = false
    }
  }
}
</script>
